<template>
  <v-btn :loading="loading" small color="red" icon flat @click="$emit('click')">
    <v-icon small>fal fa-trash</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'TrashIconButton',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
