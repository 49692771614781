



























































































































import formTemplate from '@/components/forms/formTemplate.vue'
import { computed, ComputedRef, defineComponent, provide, Ref, ref } from '@vue/composition-api'
import { IValidationObjectSingleLevel } from '@/models/errorAndValidationModels'
import AccountingAccountsAutocomplete from '@/components/autocompletes/AccountingAccountsAutocomplete.vue'
import {
  Create__Accounting_Entry__Input,
  Create__Accounting_Transaction__Input,
  Entry_Type,
  Mutation
} from '@/models/generated/graphql/ErpBackend'
import { IVuetifyDatatableHeader } from '@/models/VuetifyModels'
import { ValidateVue3ErrorObject } from '@/lib/validation'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { c } from '@/lib/Currency'
import EditIconButton from '@/components/buttons/EditIconButton.vue'
import TrashIconButton from '@/components/buttons/TrashIconButton.vue'
import { ApolloClients, useMutation, UseMutationReturn } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { CREATE_ACCOUNTING_ENTRY, CREATE_ACCOUNTING_TRANSACTION } from '@/api/graphql/Constants/Accounting'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'

export default defineComponent({
  name: 'NewAccountingEntry',
  components: {
    'new-data-form': formTemplate,
    'coa-autocomplete': AccountingAccountsAutocomplete,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    'edit-button': EditIconButton,
    'trash-button': TrashIconButton
  },
  props: {
    transactionId: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    // transaction details
    const internal_reference = ref('')
    const reference = ref('')

    // entry details
    interface AccountUI {
      id: string,
      name: string,
      account_number: string
    }
    interface CreateAccountInputUI extends Create__Accounting_Entry__Input {
      name: string,
      number: string
    }
    const account: Ref<AccountUI> = ref({
      id: '',
      name: '',
      account_number: ''
    })
    function setAccountId (a: any) {
      account.value.id = a.value
      account.value.name = a.name
      account.value.account_number = a.number
    }
    const amount: Ref<number> = ref(0)
    const entry_type: Ref<Entry_Type> = ref(Entry_Type.Credit)
    function setEntryType (value: boolean) {
      if (value) entry_type.value = Entry_Type.Debit
      else entry_type.value = Entry_Type.Credit
    }
    const note: Ref<string> = ref('')

    const entries: Ref<CreateAccountInputUI[]> = ref([])
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Account Number', value: 'number' },
      { text: 'Account Name', value: 'name' },
      { text: 'Amount', value: 'amount' },
      { text: 'Is Debit', value: 'entry_type' },
      { text: 'Notes', value: 'note' },
      { text: 'Actions', value: 'name' }
    ]

    function addEntry () {
      ValidateVue3ErrorObject(errors.value)
      if (!entryError.value) {
        entries.value.push({
          name: account.value.name,
          number: account.value.account_number,
          amount: amount.value,
          entry_type: entry_type.value,
          account_id: account.value.id,
          note: note.value,
          transaction_id: ''
        })
        account.value.id = ''
        account.value.name = ''
        account.value.account_number = ''
        entry_type.value = Entry_Type.Debit
        amount.value = 0
        note.value = ''
      }
    }

    function findEntry (entry: CreateAccountInputUI): { index: number, found: CreateAccountInputUI | undefined } {
      const found = entries.value.find(e => e === entry)
      const index = entries.value.findIndex(e => e === entry)
      return { found, index }
    }

    function editEntry (entry: CreateAccountInputUI) {
      const entryToEdit = findEntry(entry)
      if (entryToEdit.found) {
        const e = entryToEdit.found
        account.value.id = e.account_id
        account.value.account_number = e.number
        account.value.name = e.name
        entry_type.value = e.entry_type
        amount.value = e.amount
        note.value = e.note ?? ''
        entries.value.splice(entryToEdit.index, 1)
      }
    }

    function removeEntry (entry: CreateAccountInputUI) {
      const entryToEdit = findEntry(entry)
      if (entryToEdit.index > -1) entries.value.splice(entryToEdit.index, 1)
    }

    const confirm: Ref<boolean> = ref(false)
    function removeEntries () {
      if (confirm.value) {
        entries.value = []
      } else {
        confirm.value = true
        setTimeout(() => {
          confirm.value = false
        }, 2000)
      }
    }

    const errors: Ref<IValidationObjectSingleLevel> = ref({
      account_id: { value: '', validator: () => account.value.id ? '' : 'An account must be selected' },
      amount: { value: '', validator: () => !isNaN(c(amount.value).value) ? '' : 'A money amount must be entered' },
      entry_type: { value: '', validator: () => entry_type.value ? '' : 'An entry type must be selected' }
    })

    const entryError: ComputedRef<boolean> = computed(() => {
      for (const key in errors.value) {
        if (errors.value[key].value) return true
      }
      return false
    })

    function cancel () {
      emit('cancel')
    }

    const {
      mutate: AddTransaction,
      onError: onTransactionFailed,
      onDone: onTransactionSuccess
    }: UseMutationReturn<Mutation, { input: Create__Accounting_Transaction__Input }> =
      useMutation(CREATE_ACCOUNTING_TRANSACTION)
    onTransactionFailed(() => {
      footerMessage.value = 'An error has occurred adding the transaction, please contact support'
      failed.value = true
    })
    onTransactionSuccess(async (result) => {
      footerMessage.value = 'Created transaction, adding the entries...'
      const id = result?.data?.Create__Accounting_Transaction?.id ?? 0
      try {
        for (const entry of entries.value) {
          await AddEntry({
            input: {
              account_id: entry.account_id,
              amount: GetDjangoMoneyCompatibleInput(entry.amount),
              entry_type: entry.entry_type,
              note: entry.note,
              transaction_id: `${id}`
            }
          })
        }
        succeeded.value = true
        footerMessage.value = 'Successfully created transaction and entries'
        emit('refresh')
      } catch {
        footerMessage.value = 'Could not add an entry to the transaction. Please contact support.'
        failed.value = true
      } finally {
        loading.value = false
      }
    })

    const {
      mutate: AddEntry
    }: UseMutationReturn<Mutation, { input: Create__Accounting_Entry__Input }> =
      useMutation(CREATE_ACCOUNTING_ENTRY)

    async function submit () {
      loading.value = true
      if (props.transactionId) {
        try {
          for (const entry of entries.value) {
            await AddEntry({
              input: {
                account_id: entry.account_id,
                amount: GetDjangoMoneyCompatibleInput(entry.amount),
                entry_type: entry.entry_type,
                note: entry.note,
                transaction_id: props.transactionId
              }
            })
          }
          succeeded.value = true
          footerMessage.value = 'Successfully created transaction and entries'
          emit('refresh')
        } catch {
          footerMessage.value = 'Could not add an entry to the transaction. Please contact support.'
          failed.value = true
        } finally {
          loading.value = false
        }
      } else {
        await AddTransaction({
          input: {
            internal_reference: internal_reference.value,
            reference: reference.value
          }
        })
      }
    }

    const loading: Ref<boolean> = ref(false)
    const footerColor: Ref<string> = ref('green')
    const footerMessage: Ref<string> = ref('New Entries + Transaction')
    const succeeded: Ref<boolean> = ref(false)
    const failed: Ref<boolean> = ref(false)

    return {
      internal_reference,
      reference,
      account,
      setAccountId,
      amount,
      entry_type,
      setEntryType,
      note,
      entries,
      addEntry,
      editEntry,
      removeEntry,
      confirm,
      removeEntries,
      headers,
      errors,
      entryError,
      succeeded,
      failed,
      cancel,
      submit,
      loading,
      footerColor,
      footerMessage,
      c
    }
  }
})
