<template>
  <div>
    <tab-bar-wrapper @newRow="showAddGlTransactionSheet">
      <template #parent-buttons>
        <v-switch
          v-model="viewBulk"
          label="View Entries"
          color="success"
        />
      </template>
    </tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <v-fade-transition>
        <grid-wrapper
          v-if="showGrid"
          :contextMenuItems="contextMenuItems"
          :query="query"
          :filter="filters"
          :columns="columns"
          :nested-query="nestedQuery"
          :nested-filters="nestedFilters"
          :nested-columns="nestedColumns"
          :nested-grid="!viewBulk"
          :componentName="componentName"
          @ready="ready"
          @setComments="setComments"
          @cell-key-down="gridKbEventSender"
        />
      </v-fade-transition>
    </div>
    <v-fade-transition>
      <action-bar-wrapper
        v-if="showGrid"
        :commentList="commentList"
        @saveComments="saveComments"
      />
    </v-fade-transition>
    <v-bottom-sheet
      v-model="sheet"
      :inset="false"
      persistent
    >
      <new-entry v-if="sheet" :transaction-id="transactionIdToAddEntriesTo" @cancel="sheet = false" @refresh="refresh"/>
    </v-bottom-sheet>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import columns from '@/components/mixins/columns'
import grid from '@/components/mixins/grid'
import {
  COLUMNS__ACCOUNTING_ENTRIES,
  COLUMNS__ACCOUNTING_GL__BULK,
  COLUMNS__ACCOUNTING_TRANSACTIONS
} from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__ACCOUNTING_GL } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__ACCOUNTING_GL } from '@/lib/agGridKbShortCuts'
import { GET_ACCOUNTING_ENTRIES__GRID, GET_ACCOUNTING_TRANSACTIONS__GRID } from '@/api/graphql/Constants/Accounting'
import NewAccountingEntry from '@/views/accounting/NewAccountingEntry'

export default {
  name: 'GeneralLedger',
  mixins: [columns, grid],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'new-entry': NewAccountingEntry
  },
  computed: {
    query () {
      return this.viewBulk ? GET_ACCOUNTING_ENTRIES__GRID : GET_ACCOUNTING_TRANSACTIONS__GRID
    },
    columns () {
      return this.viewBulk ? COLUMNS__ACCOUNTING_GL__BULK : COLUMNS__ACCOUNTING_TRANSACTIONS
    },
    commentList () {
      const entry = {
        'note': {
          name: 'notes',
          label: 'Notes',
          text: '',
          editable: true,
          show: true
        }
      }
      const transaction = {
        'reference': {
          name: 'reference',
          label: 'Reference',
          text: '',
          editable: true,
          show: true
        }
      }

      return this.viewBulk ? entry : transaction
    },
    componentName () {
      return this.viewBulk ? 'GeneralLedger_bulk' : 'GeneralLedger_transactions'
    }
  },
  watch: {
    query: function (value) {
      this.showGrid = false
      setTimeout(() => {
        this.showGrid = true
      }, 250)
    }
  },
  data () {
    return {
      showGrid: true,
      viewBulk: true,
      sheet: false,

      filters: [],

      nestedQuery: GET_ACCOUNTING_ENTRIES__GRID,
      nestedFilters: (id) => [{ key: 'transaction__id', value: id }],

      originalComments: [],

      contextMenuItems: (params) => CONTEXT_MENU__ACCOUNTING_GL(params, this.contextMenuCallback),

      nestedColumns: COLUMNS__ACCOUNTING_ENTRIES,
      transactionIdToAddEntriesTo: ''
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__ACCOUNTING_GL(params, this.contextMenuCallback), [])
    },

    contextMenuCallback ({ functionToRun, params, data, dataKey }) {
      if (dataKey) this[dataKey] = data
      if (functionToRun) this[functionToRun](params)
    },

    showAddGlTransactionSheet (id = '') { // bring up the new data form for adding a gl transaction
      this.transactionIdToAddEntriesTo = id
      this.sheet = true
    },

    refresh () {
      this.gapi.purgeServerSideCache()
    }
  }
}
</script>
